a {
  text-decoration: none;
  color: $gray600!important;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: 700;
}

.text-semi-bold {
  font-weight: 600;
}

.text-light {
  font-weight: 300;
}

.text-right {
  text-align: right!important;
}

.text-size-normal {
  font-size: $fontSize;
}

.text-mute {
  font-size: $fontSizeMute;
}

.text-lead {
  font-size: $fontSizeLead;
  font-family: $fontFamilySecondary;
}

.linkPrimary {
  &:hover {
    color: darken($primaryColor, 5%)!important;
    text-decoration: underline;
  }
}

.textPrimary {
  color: $primaryColor!important;
}

.linkColorNormal {
  color: $gray600!important;
  &:hover {
    color: darken($gray600, 5%)!important;
    text-decoration: underline;
  }
}

.cursor-pointer {
  cursor: pointer;
}

h1 {
  font-family: $fontFamilySecondary;
  font-size: $fontSizeH1;
  font-weight: 500;
}

h2 {
  font-family: $fontFamilySecondary;
  font-size: $fontSizeH2Mobile;
  font-weight: 500;
}

h3 {
  font-family: $fontFamilySecondary;
  font-size: $fontSizeH3;
  font-weight: 500;
}

@media (min-width: $screen-lg) {
  h2 {
    font-family: $fontFamilySecondary;
    font-size: $fontSizeH2;
    font-weight: 500;
  }
}
.connection_empty_text{
  display: flex;  
  justify-content:center; 
  font-weight: 300; 
  font-size: 14px;
}
