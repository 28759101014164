.remove {
  margin-left: auto;
  margin-right: 0;
}

.image-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
