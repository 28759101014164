$fontFamily: 'Open Sans', sans-serif;
$fontFamilySecondary: 'DM Sans', sans-serif;

$heightHeaderDesktop: 105px;
$heightHeaderMobile: 54px;
$widthMainMenu: 200px;
$widthAsideMenu: 220px;
$widthBreadCrumbs: $widthMainMenu + $widthAsideMenu;


/* Responsive screen width */

$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1400px;

/* Color vars */

$primaryColor: #0576ED;

$black: #000;
$gray100: #FBFBFB;
$gray200: #F5F5F5;
$gray300: #E4E6E7;
$gray400: #AFB1B3;
$gray500: #46484A;
$gray600: #1F2123;
$white: #fff;

$warningColor: #E05263;
$successColor: #72C07F;
$blacklistColor: #000;
$flaggedColor: orange;
$pendingColor: #AFB1B3;
$pausedColor: #8c7bd2;
$redColor: red;
$unsubscribeColor: #af4baf;

$borderColor: $gray300;

$fontSize: 16px;
$fontSizeMobile: 14px;
$fontSizeMute: 14px;
$fontSizeLead: 18px;
$fontSizeH1: 34px;
$fontSizeH2: 24px;
$fontSizeH2Mobile: 16px;
$fontSizeH3: 20px;

$imgPath: './../../../assets/img'
