//vars
//$heightHeaderDesktop: 105px;
//$widthMainMenu: 200px;
//$widthAsideMenu: 220px;
//$widthBreadCrumbs: $widthMainMenu + $widthAsideMenu;

.mainHeader {
  height: $heightHeaderMobile;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: $gray100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  .logoHolder {
    display: none;
  }
  .breadCrumbsHolder {
    display: none;
  }
  .userMenuHolder {
    flex-shrink: 0;
  }
}
.mainMenu {
  display: none;
}

.asideMenu {
  display: none;
}

.mobileSubMenu {
  display: flex;
  padding: 15px 15px 0;
  justify-content: space-between;
  align-items: center;
}

.mainLayoutContent {
  min-height: 100vh;
  width: 100%;
  padding-top: $heightHeaderMobile;
}

.mainMenuDrawer {
  z-index: 98!important;
  .MuiBackdrop-root {
    top: $heightHeaderMobile;
    height: calc(100% - #{$heightHeaderMobile});
    z-index: 98;
  }
  .MuiDrawer-paper {
    top: $heightHeaderMobile;
    height: calc(100% - #{$heightHeaderMobile});
    z-index: 98;
  }
}

.mainContainer {
  margin: auto;
  padding: 0 15px;
}

.menuList {
  height: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 5px 0;
  &.mobile {
    min-width: 200px;
  }
  .menuListItem {
    display: flex;
    align-items: center;
    line-height: 18px;
    padding: 10px 20px;
    font-family: $fontFamilySecondary;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: $gray500;
    position: relative;
    a {
      color: $gray500;
    }
    &:hover {
      background-color: $gray200;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 2px;
      height: 100%;
      background-color: transparent;
      border-radius: 0 10px 10px 0;
      top: 0;
      left: 0;
    }
    &.active {
      color: $primaryColor;
      &:before {
        background-color: $primaryColor;
      }
    }
    .ico {
      display: block;
      width: 18px;
      height: 18px;
      object-fit: contain;
      object-position: center;
      margin-right: 15px;
    }
  }
  .menuListItem + .menuListItem {
    margin-top: 15px;
  }
  & > a:last-child{
    margin-top: auto !important;
  }

}

.pageHolder {
  padding: 15px 0;
}

.textPoinIP {
  padding: 15px 5px;
  font-weight: bold;
}

.pageHeadingBlock {
  background-color: $gray100;
  border: 1px solid $borderColor;
  border-radius: 10px;
  padding: 15px;
  position: relative;
  .moreIcon {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .pageHeadingBlockWrapper {
    display: flex;
    align-items: center;
    margin: -15px;
    flex-wrap: wrap;
  }
  .part {
    padding: 15px;
    flex-grow: 2;
    display: flex;
    justify-content: center;
    &.main {
      //flex-grow: 3;
    }
  }
  .partContentItem {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .hasSwitch {
    display: flex;
    align-items: center;
  }
}

.pageTableBlock {
  padding: 0;
  position: relative;
  .searchHolder {
    margin-bottom: 15px;
  }
}

//mobile
@media (min-width: $screen-sm) {

}

//mobile
@media (min-width: $screen-md) {

}

//tablet
@media (min-width: $screen-lg) {
  .pageHeadingBlock {
    padding: 54px 20px;
  }
  .mobileMainMenuHolder {
    display: none;
  }
  .mobileSubMenu {
    display: none;
  }
  .mainHeader {
    background-color: transparent;
    justify-content: unset;
    align-items: unset;
    height: $heightHeaderDesktop;
    padding: 0;
    z-index: 99;
    backdrop-filter: blur(20px);
    .logoHolder {
      flex-shrink: 0;
      width: $widthMainMenu;
      display: flex;
      align-items: center;
      background: #f8f8f8;
      justify-content: center;
      padding: 30px;
      img {
        display: block;
        max-width: 80%;
      }
    }
    .breadCrumbsHolder {
      background-color: transparent;
      width: calc(100% - #{$widthBreadCrumbs});
      padding: 30px;
      display: flex;
      align-items: center;
      .breadcrumbs {
        list-style: none;
        display: flex;
        align-items: center;
      }
      .breadcrumbsItem {
        font-family: $fontFamilySecondary;
        font-size: 26px;
        font-weight: 500;
        color: $gray400;
        cursor: pointer;
        &.active {
          color: $gray600;
          cursor: auto;
        }
      }
    }
    .userMenuHolder {
      flex-shrink: 0;
      min-width: $widthAsideMenu;
      background-color: transparent;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 30px;
    }
  }
  .mainMenu {
    width: $widthMainMenu;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 98;
    padding-top: $heightHeaderDesktop;
    background-color: #f8f8f8;
    display: block;
  }

  .asideMenu {
    width: $widthAsideMenu;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 98;
    padding-top: $heightHeaderDesktop;
    border-left: 0px solid $borderColor;
    background-color: #ffffff;
    display: block;
  }
  .mainLayoutContent {
    min-height: 100vh;
    width: 100%;
    padding-top: $heightHeaderDesktop;
    padding-left: $widthMainMenu;
    padding-right: $widthAsideMenu;
  }
  .pageTableBlock {
    //padding: 0 21px;
    .searchHolder {
      margin-bottom: 15px;
    }
  }
}

//desktop
@media (min-width: $screen-xl) {

}
