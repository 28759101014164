.wrapper-doc {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  display: flex;
  margin: 0 -10px;
  justify-content: space-between;
}
.WrapperFirst{
  display: flex; 
  flex-direction: column; 
  margin-top: 20px; 
  margin-bottom: 20px; 
  justify-content: flex-end;
}
.edpointWrapper{
  width: 60%; 
  padding: 0 20px;
}
.requestWrapper{
  width: 40%;
}
@media (max-width: 1075px) {
  .wrapper-doc{
    flex-wrap: wrap;
  }
  .edpointWrapper{
    width: 100%; 
  }
  .requestWrapper{
    width: 100%;
  }
}
@media (max-width: 1075px) {
  .wrapper-info{
    max-width: 100% !important;
    width: 100%;
  }
}
@media (max-width: 1075px) {
  .wrapper-code{
    width: 100%
  }
}

.wrapper-info {
  max-width: 60%;
  background: #FFFFFF;
  padding: 20px;
}

.info-title {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}

.info-title {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}

.info-subtitle {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  margin: 5px 0;
}

.info-text {
  /* width: 545px; */
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 20px 0;
}

.info-labale {
  margin-top: 32px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.06em;
  color: #7E7E7E;
}

.wrapper-method {
  padding: 10px 12px;
  width: fit-content;
  color: #398BEC;
  background: #F2FAFF;
  border-radius: 8px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  word-spacing: 16px;
}

.info-header {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}

.wrapper-code {
  padding: 10px 32px 0 20px;
  background: #F2F8FC;
  border-radius: 8px;
  min-width: 40%;
}

.wrapper-code-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.code-title {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.06em;
  color: #7E7E7E;
}

.copy-btn {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #DF4036;
  cursor: pointer;
}

.wrapper-code-text {
  font-family: 'Menlo';
  font-style: 'regular';
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  word-spacing: 5px;
}
.info-ul{
  padding: 0 0 0 20px;
}

.text-grey{
  color: #4F566B;
  font-weight: 700;
}
.d {
  color: #a9a9a9;
}

.bl {
  display: block;
}

.w {
  color: #272626;
}

.g {
  color: #48A559;
}

.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}

.pl30 {
  padding-left: 30px;
}
.pl35 {
  padding-left: 35px;
}
.pl40 {
  padding-left: 40px;
}
.pl50 {
  padding-left: 50px;
}
.bb{
  border-bottom: 1px solid black;
}
.fs18{
  font-size: 18px;
}
.text-orange{
  color: orange;
  font-size: 14px;
}
.text-grey-light{
  color: #4F566B;
  font-size: 14px;
}

.https-statuses{
  max-width: 700px;
  padding: 0 10px;
  border-radius: 8px;
}
.https-status{
  display: flex;
  justify-content: center;
  gap: 40px;
  margin: 20px 0;
}
.first-column{
  width: 50%;
  text-align: right;
}
.second-column{
  width: 50%;
  text-align: left;
}
.endpoint{
  padding-left: 30px;
}
.endpoint-child{
  padding-left: 60px;
}