.leadPageWrapper {
  padding: 0 15px;

  .timeLineHolder {
  }
  .timeLine {
    margin-top: 30px;
  }
}

.leadDetailsHolder {
  h2 {
    position: absolute;
    margin-top: -11px;
    margin-left: 11px;
    background-color: rgba(255, 255, 255, 0.8);
  }
}
.leadDetails {
  padding: 30px;
  background: $gray100;
  border-radius: 10px;
  border: 1px solid $borderColor;
}

.leadDetailsHeading {
  position: relative;
  .moreIcon {
    position: absolute;
    top: -8px;
    right: -20px;
  }
}

.timeLineHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leadDetailsTableLike {
  &.campSettingsTable {
    .tr {
      .td {
        &:first-child {
          white-space: nowrap;
        }
      }
    }
  }

  .tableLike {
    display: table;
    width: 100%;
    font-size: $fontSizeMute;
  }
  .tr {
    display: table-row;
    .td {
      &:first-child {
        color: $gray500;
        font-weight: 600;
        padding-left: 0;
      }
      &:last-child {
        font-weight: 300;
        padding-right: 0;
        width: 100%;
      }
    }
    &:last-child {
      .td {
        padding-bottom: 0;
      }
    }
  }
  .td {
    display: table-cell;
    padding: 15px;
  }
  .tr + .tr {
    .td {
      border-top: 1px solid $borderColor;
    }
  }
  .MuiChip-root {
    &.slChip {
      margin-right: 5px;
      margin-bottom: 5px;
      background: #EEF5FC;
      border: 1px solid #DBE8F7;
      color: #0576ED;
      border-radius: 5px;
      &:hover {
        background: darken(#EEF5FC, 2%);
      }
    }
  }
}

.MuiChip-root {
  &.tagLikeChip {
    margin-right: 5px;
    margin-bottom: 5px;
    background: #EEF5FC;
    border: 1px solid #DBE8F7;
    color: #0576ED;
    border-radius: 50px;
    padding: 10px;
    &:hover {
      background: darken(#EEF5FC, 2%);
    }
    &.Imported {
      background-color: $successColor;
      border-color: $successColor;
      color: #fff;
      &:hover {
        background: darken($successColor, 2%);
      }
    }
    &.Blacklisted {
      background-color: #000;
      border-color: #000;
      color: #fff;
      &:hover {
        background: darken(#000, 2%);
      }
    }
    &.Flagged {
      background-color: $flaggedColor;
      border-color: $flaggedColor;
      color: #fff;
      &:hover {
        background: darken($flaggedColor, 2%);
      }
    }
    &.DNC {
      background-color: $redColor;
      border-color: $redColor;
      color: #fff;
      &:hover {
        background: darken($redColor, 2%);
      }
    }
    &.success, &.active {
      background-color: $successColor;
      border-color: $successColor;
      color: #fff;
      &:hover {
        background: darken($successColor, 2%);
      }
    }
    &.warning, &.inactive, &.failed {
      background-color: $warningColor;
      border-color: $warningColor;
      color: #fff;
      &:hover {
        background: darken($warningColor, 2%);
      }
    }
    &.blackList {
      background-color: #000;
      border-color: #000;
      color: #fff;
      &:hover {
        background: darken(#000, 2%);
      }
    }
    &.pending, &.draft {
      background-color: $pendingColor;
      border-color: $pendingColor;
      color: #fff;
      &:hover {
        background: darken($pendingColor, 2%);
      }
    }
    &.flagged, &.scheduled {
      background-color: $flaggedColor;
      border-color: $flaggedColor;
      color: #fff;
      &:hover {
        background: darken($flaggedColor, 2%);
      }
    }
    &.paused {
      background-color: $pausedColor;
      border-color: $pausedColor;
      color: #fff;
      &:hover {
        background: darken($pausedColor, 2%);
      }
    }
    &.completed {
      background-color: $primaryColor;
      border-color: $primaryColor;
      color: #fff;
      &:hover {
        background: darken($primaryColor, 2%);
      }
    }
    &.stop {
      background-color: $redColor;
      border-color: $redColor;
      color: #fff;
      &:hover {
        background: darken($redColor, 2%);
      }
    }
  }
}

@media (min-width: $screen-lg) {
  .leadDetailsHolder {
    h2 {
      margin-top: -16px;
      margin-left: 16px;
    }
  }
}

@media (min-width: $screen-xxl) {
  .leadPageWrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    .leadDetails {
      padding: 30px;
      background: $gray100;
      border-radius: 10px;
      border: 1px solid $borderColor;
    }
    .leadBox{
      width: 55%;
    }
    .timeLineHolder {
      padding: 30px;
      width: 45%;
    }
    .timeLine {
      padding: 30px 10px;
      margin-top: 0;
    }
  }
}

.phoneStatusLable{
  display: inline-flex;
  color: #fff;
  background-color: $gray400;
  margin: 0px 10px;
  font-weight: 600;
  font-size: 11px;
  padding: 3px 10px 3px 10px;
  line-height: 15px;
  border-radius: 5px;
  vertical-align: middle;
  align-items: center;
  width: auto;
  justify-content: center;
  &.Unsubscribed {
    background-color: $unsubscribeColor;
  }
  &.Active {
    background-color: $successColor;
  }
  &.Inactive {
    background-color: $warningColor;
  }
  &.Pending {
    background-color: $pendingColor;
  }

  &.DNC {
    background-color: $redColor;
  }
}
