.btnHolder {
  &.hasPadTop {
    padding-top: 30px;
  }
  &.hasPadBottom {
    padding-bottom: 30px;
  }
  &.twoBtn {
    .part {
    }
    .part + .part {
      margin-top: 15px;
    }
  }
}

.dropDownLink {
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  outline: none;
  box-shadow: none;
  font-size: $fontSizeMute;
  cursor: pointer;
}

@media (min-width: $screen-sm) {
  .btnHolder {
    &.twoBtn {
      display: flex;
      .part {
        width: calc(50% - 15px);
      }
      .part + .part {
        margin-top: 0;
        margin-left: 30px;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}