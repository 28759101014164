.searchHolder {
  .resultHolder {
    margin-top: 15px;
  }
}

.chipLike {
  display: inline-flex;
  color: #fff;
  background-color: $gray400;
  font-weight: 600;
  font-size: 11px;
  padding: 5px 10px;
  line-height: 15px;
  border-radius: 50px;
  vertical-align: middle;
  align-items: center;
  width: 100px;
  justify-content: center;

  &.Blacklisted {
    background-color: $blacklistColor;
  }

  &.Imported {
    background-color: $successColor;
  }

  &.Subscribed {
    background-color: $successColor;
  }

  &.Flagged {
    background-color: $flaggedColor;
  }

  &.Pending {
    background-color: $pendingColor;
  }

  &.DNC {
    background-color: $redColor;
  }

  &.success, &.active {
    background-color: $successColor;
  }

  &.warning, &.inactive, &.failed, &.Unsubscribed {
    background-color: $warningColor;
  }

  &.Unsubscribed {
    background-color: $unsubscribeColor;
  }

  &.primary {
    background-color: $primaryColor;
  }

  &.blackList {
    background-color: $blacklistColor;
  }

  &.flagged, &.scheduled {
    background-color: $flaggedColor;
  }

  &.pending, &.draft {
    background-color: $pendingColor;
  }

  &.paused {
    background-color: $pausedColor;
  }

  &.completed {
    background-color: $primaryColor;
  }

  &.stop {
    background-color: $redColor;
  }

}

.fileDropHolder {
  border: 2px dashed $primaryColor;
  padding: 30px;
  border-radius: 10px;
  cursor: pointer;

  .icoHolder {
    img {
      display: block;
      margin: auto;
    }
  }
}

.MuiTextField-root {
  &.freqAdornment {
    .MuiOutlinedInput-adornedEnd {
      padding-right: 7px;
    }

    .MuiInputAdornment-root {
      .MuiInput-root {
        &:before,
        &:after {
          display: none;
        }
      }

      .MuiSelect-select:focus {
        background-color: transparent;
      }
    }
  }
}

.fileProgressHolder {
  .fileProgress {
    list-style: none;
  }

  .fileProgressItem {
    display: flex;
    align-items: center;

    .icoHolder {
      flex-shrink: 0;
      margin-right: 15px;

      img {
        display: block;
      }
    }
  }

  .progress {
    width: calc(100% - 45px);
  }

  .progressStatus {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}


.editTextOnClick {
  display: flex;
  align-items: center;

  .editBtn {
    font-size: 15px;
    padding: 10px;
  }

  .saveBtn {
    font-size: 15px;
    padding: 10px;
  }

  .captionPart {
    width: 100%;

    .caption {
      padding: 6px 0 7px;
      font-size: 14px;
      line-height: 22px;
      display: inline-block;
      cursor: pointer;
    }
  }

  .btnPart {
    width: 38px;
    margin-left: 10px;
  }
}

.refresh-segments {
      position: absolute;
      right: 0;
      border-radius: 50px;
}

.refresh-button {
      padding: 5px 20px;
      font-size: 13px;
      border-radius: 50px !important;
}

.refresh-sub-segments {
  position: absolute;
  right: 0;
  border-radius: 50px;
}

// mobile

@media (max-width: 991px) {
  .refresh-segments {
    right: 40px;
    top: -55px;
    border-radius: 50px;
  }

  .refresh-sub-segments {
    right: 40px;
    top: -152px;
    border-radius: 50px;
  }
}

.campaign-today {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  position: absolute;
  right: 165px;
  top: -6px;
  border-radius: 50px;
  margin-top: 15px;
  font-size: 14px;
  z-index: 1;

  @media (max-width: 1800px) {
    right: unset;
    left: 0;
    top: -15px;
  }
}

.inputHolder {
  padding-bottom: 10px;
  &:last-child {
    padding-bottom: 0;
  }
}

@media (min-width: $screen-lg) {
  .searchHolder {
    display: flex;
    align-items: center;
    gap: 5px;

    &.hasFilter {
      justify-content: space-between;
    }

    .resultHolder {
      margin-left: 15px;
      margin-top: 0;
    }

    .inputHolder {
      max-width: 300px;
      width: 100%;
      padding-bottom: 0;
    }
  }
}

.campaignFilters {
  @media (min-width: 1161px) and (max-width: 1800px) {
    padding-top: 60px;
  }
  @media (max-width: 1160px) {
    padding-top: 92px;
  }
}

.senders-input-group {
  gap: 4px;
  margin-top: 15px;
  margin-bottom: 15px;
}
