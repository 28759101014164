@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import "variables";
@import "reset";
@import "colors";
@import "borders";
@import "wrappers";
@import "typography";
@import "spacings";
@import "buttons";
@import "icons";
@import "tables";
@import "forms";
@import "lists";
@import "modal";
@import "body";
@import "layout";
@import "tabs";
@import "auth";
@import "single-lead";
@import "segments";
@import "campaigns";
@import "rules";

.welcomeHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
  height: calc(100vh - #{$heightHeaderDesktop});
}

.importPopover {
  .MuiPaper-root {
    width: 496px;
    border-radius: 10px;
  }
}

.importPopoverContent {
  .ipTitleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid $borderColor;
  }
  .fileProgressHolder {
    padding: 15px;
    .progress {
      width: calc(100% - 75px);
    }
  }
  .fileProgressItem {
    margin-bottom: 30px;
    align-items: center;
    &.lookUpItem {
      margin-bottom: 0;
    }
  }
  .lookUpItem + .lookUpItem {
    margin-top: 15px;
  }
  .statusIcoHolder {
    margin-left: 15px;
    flex-shrink: 0;
    .MuiSvgIcon-root {
      width: 15px;
      display: block;
    }
  }
}

td>a {
  display: inline-block;
  width: 100%;
}

.downloadWrapper {
  padding: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .fileWrapper {
    display: flex;
    align-items: flex-end;
  }
  .btnDownload {
    margin-left: 20px;
  }
}
.seeMoreWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.segmentationItemWrapper {
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 12px;
}
.segmentationItem {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  @media (max-width: $screen-sm) {
    flex-direction: column;
  }
}
.segmentationItemValues {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.segmentationItemDeleteBtn {
  @media (max-width: $screen-sm) {
    position: absolute !important;
    right: -35px;
  }
}
.segmentationItemDivider {
  margin-bottom: 15px !important;
  width: 92%;
}
