.segmentsFilterHolder {
  .titleDropdownHolder {
    h3 {
      display: inline-block;
      position: relative;
      padding-right: 10px;
      cursor: pointer;
      &:after {
        content: "";
        display: block;
        width: 7px;
        height: 4px;
        background-image: url("#{$imgPath}/caret.svg");
        background-size: contain;
        background-position: center;
        position: absolute;
        top: 50%;
        margin-top: -2px;
        right: 0;
      }
    }
  }
}

.segmentsListDropDown {
  min-width: 220px;
  overflow: hidden;
  position: relative;
  //max-height: 425px;
  padding-top: 53px;
  .MuiOutlinedInput-input {
    background: #fff;
    border: 0;
    border-bottom: 1px solid $borderColor;
    padding: 15px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
  .searchHolder {
    flex-shrink: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .listHolder {
    padding: 15px 0;
  }
  .segmentsList {
    list-style: none;
    overflow: auto;
    li {
      padding: 10px 15px;
      font-size: 14px;
      font-family: $fontFamilySecondary;
      cursor: pointer;
      &:hover {
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }
}

.segmentsDropDown {
  overflow: hidden;
  .MuiPopover-paper {
    max-height: 425px;
  }
}

.segmentsFilter {
  padding-bottom: 30px;
  .filterRow {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .buttons {
      flex-shrink: 1;
      margin-right: 10px;
    }
    .MuiButton-root, .MuiChip-root {
      margin-right: 5px;
      margin-bottom: 5px;
      background: $gray100;
      border: 1px solid $borderColor;
      color: $gray500;
      border-radius: 10px;
      &:hover {
        background: $gray200;
      }
      .MuiChip-icon {
        color: #2E3A59;
      }
      .MuiChip-deleteIcon {
        color: #2E3A59;
      }
    }
    .input {
      flex-grow: 1;
    }
    .MuiOutlinedInput-input {
      background: #fff;
      padding: 4px 5px;
    }
    .MuiOutlinedInput-notchedOutline, .MuiInput-underline:before, .MuiInput-underline:hover:before, .MuiInput-underline:active:before {
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid $borderColor;
    }
    .MuiInput-underline:after {
      border: none;
    }
  }
}
