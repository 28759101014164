.authLayout {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.authContent {
  display: flex;
  align-items: center;
  padding: 30px 15px;
  flex-grow: 1;
  justify-content: center;
  .imgPart {
    flex-shrink: 0;
    width: 479px;
    img {
      display: block;
      max-width: 100%;
    }
  }
  .formPart {
    flex-shrink: 0;
    max-width: 378px;
    width: 100%;
    //margin-left: 132px;
  }
}

.authFormNav {
  list-style: none;
  display: flex;
  align-items: center;
  .authFormNavItem {
    a {
      color: $gray400;
      text-decoration: none;
      border-bottom: 1px solid transparent;
      padding-bottom: 2px;
      display: block;
      font-family: $fontFamilySecondary;
      font-size: $fontSizeH2;
      &.active {
        color: $primaryColor;
        border-color: $primaryColor;
      }
    }
  }
  .authFormNavItem + .authFormNavItem {
    margin-left: 30px;
  }
}

