.modal {
  .modalBody {
    width: 496px;
    //max-width: 496px;
    //width: 100%;
    padding: 30px;
  }
}
.menuStatus {
  top: 45px!important;
}
